<template>
  <div style="padding-bottom: 300px">
    <b-card v-if="is_admin">
      <b-row style="margin-bottom: -1rem;">
        <b-col cols="3">
          <b-form-group :label="$t('common.company')">
            <v-select
              label="text"
              :options="company_options"
              :reduce="text => text.value"
              v-model="company_id"
              :clearable="false"
              :searchable="false"
              @option:selected="changeCompany"
            >
              <template #no-options>
                {{ $t('common.no_options') }}
              </template>
            </v-select>
          </b-form-group>

        </b-col>
        <b-col cols="3">
          <b-form-group :label="$t('common.facility')">
            <v-select
              label="text"
              :options="facility_options"
              :reduce="text => text.value"
              v-model="facility_id"
              :clearable="false"
              :searchable="false"
              @option:selected="changeFacility"
            >
              <template #no-options>
                {{ $t('common.no_options') }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="6" class="d-flex justify-content-end align-items-end">
          <b-form-group>
            <BButton variant="info" class="mt-1 float-right" @click="goGenerateTips">
              {{ $t('device_report.generate_tips') }}
            </BButton>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <b-tabs ref="tabs" :nav-wrapper-class="{'d-none': reports.length === 1}">

        <b-tab>
          <template #title>
            <feather-icon icon="FileTextIcon"/>
            <span>{{ $t('common.all') }}</span>
          </template>
        </b-tab>

        <b-tab v-for="(report) in reports">
          <template #title>
            <feather-icon icon="FileTextIcon"/>
            <span>{{ report.title }}</span>
          </template>
          <div class="reportWrapper" :class="{'m-2': reports.length > 1}">
            <edit-field-header
              :title="report.title"
              :show-back-btn="false"
              :show-hr="reports.length === 1"
            />
            <edit-field
              :fields="report.fields"
              :row-data="search[report.type]"
              :form-id="reports.findIndex(r => r.type === report.type)"
              :post-api="report.api"
              :submit-btn-text="$t('device_report.generate_report')"
              :submit-action="generateReport"
              :show-hr-before-submit-btn="reports.length === 1"
              :permission="report.permission"
            >
              <template #SlotMember="scope">
                <SlotMember
                  :search="search[report.type]"
                  :facility-id="facility_id"
                  :root-item="scope.rootItem"
                />
              </template>
            </edit-field>
            <hr class="w-100"/>
          </div>
        </b-tab>

      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue'

import common from '@/common'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import ButtonPermission from '@/components/ButtonPermission'
import SlotMember from '@/views/bedExitMonitor/deviceReport/SlotMember'
import TableSearch from '@/components/TableSearch'
import EditFieldHeader from '@/components/EditFieldHeader'
import EditField from '@/components/EditField2'

export default {
  name: 'Index',
  components: {
    ButtonPermission,
    flatPickr,
    vSelect,
    SlotMember,
    TableSearch,
    EditFieldHeader,
    EditField,
  },
  data() {
    return {
      is_admin: common.isAdmin(),
      company_options: [],
      facility_options: [],
      company_id: common.getCompanyId(),
      facility_id: common.getFacilityId(),
      show_report_type: 'all',
      head_guide: [
        {
          type: 'all',
          label: common.getI18n('common.all')
        },
        {
          type: 'status',
          label: common.getI18n('device_report.device_status_report')
        },
        {
          type: 'person',
          label: common.getI18n('device_report.device_person_report')
        },
        {
          type: 'time',
          label: common.getI18n('device_report.device_time_report')
        },
        {
          type: 'sleep',
          label: common.getI18n('device_report.device_sleep_report')
        },
      ],
      // 用戶填寫的數據，用以提交表單
      search: {
        status: {
          groupID: null,
          startDate: null,
          endDate: null
        },
        person: {},
        time: {},
        sleep: {},
      },
      // 表單的默認信息
      reports: [
        {
          title: common.getI18n('device_report.device_status_report'),
          type: 'status',
          permission: 'Mon_Menu_Report',
          api: '/bemonitor/GetStatusReport',
          fields: [
            {
              // label: common.getI18n('device_report.group'),
              label: 'device_report.group',
              name: 'groupID',
              type: 'select',
              rule: 'required',
              options: [],
            },
            {
              label: 'device_report.start_date',
              name: 'startDate',
              type: 'date',
              rule: 'required',
              config: {
                wrap: true,
                // mode: 'range',
                // locale: { rangeSeparator: ' ~ ' } ,
                minDate: null,
                maxDate: this.$moment()
                  .add(1, 'hours')
                  .format('YYYY-MM-DD'),
              },
              default: this.$moment()
                .add(-6, 'days')
                .format('YYYY-MM-DD'),
              on_change: ({dateType, dateStr}) => {
                this.handleDateChange({
                  formType: 'status',
                  dateType,
                  dateStr
                })
              },
            },
            {
              label: 'device_report.end_date',
              name: 'endDate',
              type: 'date',
              rule: 'required',
              config: {
                wrap: true,
                minDate: null,
                maxDate: this.$moment()
                  .add(1, 'hours')
                  .format('YYYY-MM-DD'),
              },
              default: this.$moment()
                .add(1, 'hours')
                .format('YYYY-MM-DD'),
              on_change: ({dateType, dateStr}) => {
                this.handleDateChange({
                  formType: 'status',
                  dateType,
                  dateStr
                })
              },
            },
            {
              label: 'device_report.start_time',
              name: 'startTime',
              type: 'timeHours',
              rule: 'required',
              default: '00:00'
            },
            {
              label: 'device_report.end_time',
              name: 'endTime',
              type: 'timeHours',
              rule: 'required',
              default: this.$moment()
                .add(1, 'hours')
                .format('HH:00')
            },
          ]
        },
        {
          title: common.getI18n('device_report.device_person_report'),
          type: 'person',
          permission: 'Mon_Menu_Report',
          api: '/bemonitor/GetPersonalRecordReport',
          fields: [
            {
              label: 'device_report.member',
              name: 'memberID',
              rule: 'required',
              type: 'slot',
              slot: 'SlotMember',
              validate_in_slot: true
            },
            {
              label: 'device_report.start_date',
              name: 'startDate',
              type: 'date',
              rule: 'required',
              config: {
                wrap: true,
                minDate: null,
                maxDate: this.$moment()
                  .add(1, 'hours')
                  .format('YYYY-MM-DD'),
              },
              default: this.$moment()
                .add(-6, 'days')
                .format('YYYY-MM-DD'),
              on_change: ({dateType, dateStr}) => {
                this.handleDateChange({
                  formType: 'person',
                  dateType,
                  dateStr
                })
              },
            },
            {
              label: 'device_report.end_date',
              name: 'endDate',
              type: 'date',
              rule: 'required',
              config: {
                wrap: true,
                minDate: null,
                maxDate: this.$moment()
                  .add(1, 'hours')
                  .format('YYYY-MM-DD'),
              },
              default: this.$moment()
                .add(1, 'hours')
                .format('YYYY-MM-DD'),
              on_change: ({dateType, dateStr}) => {
                this.handleDateChange({
                  formType: 'person',
                  dateType,
                  dateStr
                })
              },
            },
            {
              label: 'device_report.start_time',
              name: 'startTime',
              type: 'timeHours',
              rule: 'required',
              default: '00:00'
            },
            {
              label: 'device_report.end_time',
              name: 'endTime',
              type: 'timeHours',
              rule: 'required',
              default: this.$moment()
                .add(1, 'hours')
                .format('HH:00')
            },
          ]
        },
        {
          title: common.getI18n('device_report.device_time_report'),
          type: 'time',
          permission: 'Mon_Menu_Report',
          api: '/bemonitor/GetTimePeriodStatReport',
          fields: [
            {
              label: 'device_report.date',
              name: 'startDate',
              type: 'date',
              rule: 'required',
              config: {
                wrap: true,
                minDate: null,
                maxDate: this.$moment()
                  .add(1, 'hours')
                  .format('YYYY-MM-DD'),
              },
              default: this.$moment()
                .format('YYYY-MM-DD')
            },
            {
              label: 'device_report.start_time',
              name: 'startTime',
              type: 'timeHours',
              rule: 'required',
              default: '00:00'
            },
            {
              label: 'device_report.end_time',
              name: 'endTime',
              rule: 'required',
              type: 'timeHours',
              default: this.$moment()
                .add(1, 'hours')
                .format('HH:00')
            },
            {
              label: 'device_report.group_type',
              name: 'isGroupByFloor',
              type: 'radio',
              rule: 'required',
              options: [
                {
                  text: common.getI18n('device_report.group_type_floor'),
                  value: true
                },
                {
                  text: common.getI18n('device_report.group_type_room'),
                  value: false
                },
              ],
              default: true
            }
          ]
        },
        {
          title: common.getI18n('device_report.device_sleep_report'),
          type: 'sleep',
          permission: 'Mon_Menu_Report',
          api: '/bemonitor/GetSleepCycleReport',
          fields: [
            {
              label: 'device_report.date',
              name: 'startDate',
              type: 'date',
              rule: 'required',
              config: {
                wrap: true,
                minDate: null,
                maxDate: this.$moment()
                  .add(1, 'hours')
                  .format('YYYY-MM-DD'),
              },
              default: this.$moment()
                .format('YYYY-MM-DD')
            },
            {
              label: 'device_report.start_time',
              name: 'startTime',
              type: 'timeHours',
              rule: 'required',
              default: '00:00'
            },
            {
              label: 'device_report.end_time',
              name: 'endTime',
              type: 'timeHours',
              rule: 'required',
              default: this.$moment()
                .add(1, 'hours')
                .format('HH:00')
            },
            {
              label: 'device_report.group_type',
              name: 'isGroupByFloor',
              type: 'radio',
              rule: 'required',
              options: [
                {
                  text: common.getI18n('device_report.group_type_floor'),
                  value: true
                },
                {
                  text: common.getI18n('device_report.group_type_room'),
                  value: false
                },
              ],
              default: true
            }
          ]
        },
      ]
    }
  },
  mounted() {
    if (common.isAdmin()) {
      common.getCompanyOptions()
        .then(res => {
          this.company_options = res
        })

      common.getFacilityOptions(common.getCompanyId())
        .then(res => {
          this.facility_options = res
        })
    }
    this.getGroupOptions(common.getFacilityId())

    // set default value from fields to forms
    this.reports.forEach(report => {
      report.fields.forEach(field => {
        if (field.default !== undefined && field.name) {
          this.$set(this.search[report.type], field.name, field.default)
        }
      })
    })
  },
  methods: {

    handleDateChange({
      formType,
      dateType,
      dateStr
    }) {
      const reportIndex = this.reports.findIndex(report => report.type === formType)
      const fields = this.reports[reportIndex].fields
      const startDateIndex = fields.findIndex(field => field.name === 'startDate')
      const endDateIndex = fields.findIndex(field => field.name === 'endDate')
      common.syncFieldsOfDateRange({
        dayRange: 365,
        changedDateType: dateType,
        changedDateStr: dateStr,
        vmDateWrapperObj: this.search[formType],
        vmStartDateConfig: startDateIndex >= 0 ? fields[startDateIndex].config : undefined,
        vmEndDateConfig: endDateIndex >= 0 ? fields[endDateIndex].config : undefined,
      })
    },

    async changeCompany(data) {
      this.facility_id = null
      this.clearGroupOptions()

      const FacilityOptions = await common.getFacilityOptions(data.value)
      const DefaultFacilityId = typeof (FacilityOptions[0]?.value) === 'number' ? FacilityOptions[0].value : null
      this.facility_options = FacilityOptions
      this.facility_id = DefaultFacilityId
      DefaultFacilityId !== null && this.changeFacility(FacilityOptions[0])

      common.syncSearchFields({
        companyID: data.value,
        facilityID: DefaultFacilityId,
        floorID: null,
        roomID: null,
      })
    },

    changeFacility: function (data) {
      common.syncSearchFields({
        companyID: this.company_id,
        facilityID: data.value,
        floorID: null,
        roomID: null,
      })
      this.clearGroupOptions()
      this.getGroupOptions(data.value)
    },

    getGroupOptions: function (facility_id) {
      common.getGroupOptions(facility_id)
        .then(res => {
          for (const i in this.reports) {
            if (this.reports[i].type === 'status') {
              this.reports[i].fields[0].options = res
              break
            }
          }
        })
    },

    clearGroupOptions: function () {
      for (const i in this.reports) {
        if (this.reports[i].type === 'status') {
          this.search['status'].groupID = null
          this.reports[i].fields[0].options = []
          break
        }
      }
    },

    clear: function (type, name) {
      this.search[type][name] = null
    },

    showReport: function (type) {
      this.show_report_type = type
    },

    generateReport: function (data, api) {
      const where = data
      const startMoment = this.$moment(`${where.startDate} ${where.startTime}`)
      const endMoment = where.endDate ? this.$moment(`${where.endDate} ${where.endTime}`) : this.$moment(`${where.startDate} ${where.endTime}`)
      if (endMoment.diff(startMoment) > 0) {
        where['facilityID'] = this.facility_id
        common.apiDownloadData({
          url: api,
          params: where
        })
      } else {
        common.showError('ReportConditionTimeError')
      }
    },

    goGenerateTips: function () {
      common.apiGetData('/bemonitor/GetReportChartGuidePath')
        .then(res => {
          const path = 'https://view.officeapps.live.com/op/view.aspx?src=' + common.getServerUrl() + res.data.path
          window.open(path, '_blank')
        })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.reportWrapper > hr {
  margin: 3rem 0;
}

.tabs > :first-child {
  margin-bottom: 3rem;
  border-bottom: 1px solid $gray-100;

  .dark-layout & {
    border-bottom-color: $gray-700;
  }
}

.tab-content .tab-pane {
  &.active:first-child ~ .tab-pane {
    display: block !important;
  }

  &.active, &:last-of-type {
    > .reportWrapper > hr {
      display: none;
    }
  }
}

</style>
