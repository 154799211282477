<template>
  <div>
    <validation-observer
      tag="b-row"
      class="mb-05"
    >
      <validation-provider
        tag="div"
        class="w-100"
        #default="{ errors }"
        :name="$t(rootItem.label)"
        :rules="rootItem.rule"
      >
        <b-col cols="12" class="d-flex">
          <b-input-group @click="selectMember">
            <b-form-input readonly class="cursor-pointer" v-model="member"/>
            <b-input-group-append>
              <b-button variant="outline-primary" style="height: 38px">
                {{ $t('common.select') }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col cols="12" v-show="errors.length !== 0"><small class="text-danger">{{ errors[0] }}</small></b-col>
      </validation-provider>
    </validation-observer>
    <modal-member ref="model_member" @changeMember="changeMember" :isMultiSelect="isMultiSelect"/>
  </div>
</template>

<script>
import ModalMember from '@/views/bedExitMonitor/deviceReport/ModalMember'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import '@validations'

export default {
  name: 'SlotMember',
  components: {
    ModalMember,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    facilityId: { type: Number },
    search: { type: Object },
    isMultiSelect: {
      type: Boolean,
      default: false
    },
    rootItem: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      member: ''
    }
  },
  watch: {
    facilityId: function (facilityID) {
      delete this.search.memberID
      this.member = ''
      this.$refs.model_member.changeFacilityId(facilityID)
    }
  },
  methods: {
    selectMember: function () {
      this.$refs.model_member.show_modal = true
    },
    changeMember: function (options, isMultiSelect = false) {
      if (isMultiSelect) {
        const members = []
        Array.prototype.forEach.call(options, (option) => {
          members.push(option.memberName + '（' + option.bedNumber + '）')
        })
        this.member = members.join(', ')
      } else {
        const option = options
        this.search.memberID = option.memberID
        this.member = option.memberName + '（' + option.bedNumber + '）'
      }
    }
  }
}
</script>

<style scoped>
[dir] .form-control[readonly] {
  background-color: inherit;
}
</style>
