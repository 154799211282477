<template>
  <modal
      :title="$t('device_report.select_member')"
      :confirm-fun="submit"
      :cancel-fun="hideModal"
      :visible="show_modal"
      :ok-only="true"
      :no-close-on-backdrop="true"
  >
    <b-form>
      <b-row class="mb-1">
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon"/>
            </b-input-group-prepend>
            <b-form-input type="text" v-model="search_content" :placeholder="$t('group.search_member_tips')"/>
          </b-input-group>
        </b-col>
      </b-row>

      <b-row v-if="isMultiSelect">
        <b-col cols="12" class="mb-1 d-flex justify-content-start">
          <b-form-checkbox
              :checked="isAllSelected"
              @change="toggleAllSelect"
              class="m-0 float-right"
          >
            <span> {{ $t('device_raw_data.select_all') }}</span>
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-row>
        <b-col v-if="!isMultiSelect" cols="12">
          <b-form-radio-group
              class="group-edit d-flex flex-wrap align-content-start"
              style="height: 200px; padding: 5px 0 5px 0; overflow-y: scroll"
              v-model="selected"
          >
            <b-col
                class="col-6"
                v-for="option in member_options"
                style="padding: 5px 15px;"
            >
              <b-form-radio
                  :value="option"
                  class="m-0"
              >
                {{ option.memberName }}（{{ option.bedNumber }}）
              </b-form-radio>
            </b-col>
          </b-form-radio-group>
        </b-col>
        <b-col v-else cols="12">
          <b-form-checkbox-group
              class="group-edit d-flex flex-wrap align-content-start"
              style="height: 200px; padding: 5px 0 5px 0; overflow-y: scroll"
              v-model="selected"
          >
            <b-col
                class="col-6"
                v-for="option in member_options"
                style="padding: 5px 15px;"
            >
              <b-form-checkbox
                  :value="option"
                  class="m-0"
              >
                {{ option.memberName }}（{{ option.bedNumber }}）
              </b-form-checkbox>
            </b-col>
          </b-form-checkbox-group>
        </b-col>
      </b-row>

    </b-form>
  </modal>
</template>

<script>
import vSelect from 'vue-select'
import common from '@/common'
import Modal from '@/components/Modal'

export default {
  name: 'ModalMember',
  components: {
    Modal,
    vSelect,
  },
  props: {
    isMultiSelect: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      selected: undefined,
      show_modal: false,
      facility_id: common.getFacilityId(),
      search_content: '',
      // member_id: 0,
      member_options: [],
    }
  },
  computed: {
    isAllSelected() {
      return this.selected && this.selected.length === this.member_options.length
    }
  },
  watch: {
    search_content: function () {
      this.member_options = []
      this.selected = undefined
      this.getMember()
    },
  },
  mounted() {
    this.getMember()
  },
  methods: {
    submit: function () {
      this.show_modal = false
      this.$emit('changeMember', this.selected, this.isMultiSelect)
    },
    hideModal: function () {
      this.show_modal = false
    },
    getMember: function () {
      const facility_id = this.facility_id ? this.facility_id : 0
      common.apiGetData('/group/GetMemberBeds?facilityID=' + facility_id + '&searchContent=' + this.search_content)
          .then(res => {
            this.member_options = res.data.members
          })
    },
    changeFacilityId: function (facilityID) {
      this.facility_id = facilityID
      this.search_content = ''
      // this.member_id = 0
      this.selected = undefined
      this.member_options = []
      this.getMember()
    },
    toggleAllSelect: function() {
      if(this.selected && this.selected.length !== this.member_options.length) {
        this.selected = this.member_options
      } else {
        this.selected = []
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>

</style>
